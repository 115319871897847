const BulletPoint = ({ color, className }) => {
  const bulletStyle = {
    color: color,
    verticalAlign: 'middle',
    fontSize: '1.5rem',
    lineHeight: '1',
  }

  const spanClassName = className ? `bullet ${className}` : 'bullet'

  return (
    <span className={spanClassName} style={bulletStyle}>
      &#x2022;
    </span>
  )
}

export default BulletPoint
