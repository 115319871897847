import _ from 'lodash'

export const getInfoBoxKeys = (platform) => {
  let infoBoxes = ['audience_gender', 'audience_age', 'audience_countries']

  const instagram_keys = ['audience_interests', 'engagement_rate', 'audience_cities']

  if (platform === 'instagram') {
    infoBoxes = [...infoBoxes, ...instagram_keys]
  }

  const other_keys = ['avg_views', 'engagement_rate', 'avg_likes']
  if (['tiktok', 'youtube'].includes(platform)) {
    infoBoxes = [...infoBoxes, ...other_keys]
  }

  if (platform === 'all') {
    infoBoxes = _.uniq([...infoBoxes, ...instagram_keys, ...other_keys])
  }
  return infoBoxes
}
